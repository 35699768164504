<template>
  <div class="brandIntroduction">
    <!-- 背景图 -->
    <div class="img-back">
      <img src="/static/big-logo.png" alt="" />
    </div>
    <div class="msg">
      悦心车是北京华奥旗下高端汽车零售品牌<br />创造有别于新车与传统二手车的智慧购车选择
    </div>
    <div class="img-back">
      <img src="/static/aboutus/exhibition.png" alt="" />
    </div>
    <div class="msg">
      搭载深耕十余年的<br />豪华品牌车系技术检测与整备升级能力<br />致力于让每一位车主<br />用二手车的价格<br />享受新车的品质与服务
    </div>
    <div class="content">
      <div class="lists">
        <div class="list">
          <img src="/static/aboutus/1.png" alt="" />
          <div class="list-title">「智造车」车辆展区</div>
          <div class="list-info">
            豪华汽车品牌热销车型<br />体验一站式购车服务
          </div>
        </div>
        <div class="list">
          <img src="/static/aboutus/2.png" alt="" />
          <div class="list-title">智能整备车间 技术研发中心</div>
          <div class="list-info">
            可视化车源「再制造」场景<br />感受「智造车」升级流程
          </div>
        </div>
        <div class="list">
          <img src="/static/aboutus/3.png" alt="" />
          <div class="list-title">汽车文化生活</div>
          <div class="list-info">
            汽车博物馆、多功能娱乐空间<br />畅享车主智慧生活
          </div>
        </div>
      </div>
      <div class="video-wraper">
        <video
          ref="videoDom"
          src="https://www.yuexinche.com/pc/1.mp4"
          style="width:100%;height:100%"
           poster="/static/service/video-poster.png"
          preload="meta"
        ></video>
        <div class="play" ref="playDom" @click="play" >
          <img src="/static/service/play.png" alt="" />
          <p>点击观看完整视频</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    play() {
      let videoDom = this.$refs.videoDom
      console.log(videoDom);
      videoDom.play()
      let playDom = this.$refs.playDom
      playDom.style.display = 'none'
    }
  },
};
</script>

<style lang="less" scoped>
.brandIntroduction {
  padding-bottom: 100px;
  width: 100%;
  .img-back {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .msg {
    width: 100%;
    padding: 50px 0;
    text-align: center;
    font-size: 24px;
    color: #636363;
  }
  .content {
    width: 1360px;
    margin: 0 auto;
    .lists {
      width: 100%;
      display: flex;
      height: 648px;
      justify-content: space-between;
      .list {
        width: 400%;
        text-align: center;
        height: 100%;
        color: #636363;
        img {
          width: 400px;
          height: 400px;
        }
        .list-title {
          margin-top: 50px;
          font-weight: 550;
          font-size: 26px;
        }
        .list-info {
          margin-top: 20px;
          font-size: 18px;
        }
      }
    }
    .video-wraper {
      width: 100%;
      height: 664px;
      position: relative;
      video {
        width: 100%;
        height: 664px;
      }
      .play{
        cursor: pointer;
        position: absolute;
        left: calc(50% - 75px);
        top: calc(50% - 75px);
        height: 150px;
        width: 150px;
        text-align: center;
        color: #fff;
        img{
          width: 80px;
          height: 80px;
          display: inline-block;
          margin-bottom: 15px;
        }
        
      }
    }
  }
}
</style>
